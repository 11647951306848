var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"max-w-540 th-mx-auto"},[_c('h5',{staticClass:"th-mb-24"},[_vm._v(" "+_vm._s(_vm.$translation.t("product.step.data_usage_purpose.title"))+" ")]),(_vm.loading)?_c('page-loader'):_c('div',[_vm._l((_vm.purposes || []),function(purpose,pKey){return _c('th-card',{key:pKey,staticClass:"th-px-24 project-document th-mt-12",attrs:{"body-classes":"th-p-12"}},[_c('div',{staticClass:"project-document__item th-py-16 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center flex-grow-1"},[(purpose.uuid)?_c('v-icon',{staticClass:"th-mr-8",attrs:{"color":"success","size":"24"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e(),_c('h6',{staticClass:"subtitle2 th-m-0",domProps:{"innerHTML":_vm._s(purpose.name)}})],1),(purpose.uuid)?_c('v-btn',{attrs:{"color":"primary","text":"","to":{
              name: _vm.routeNameToEditPurpose,
              params: Object.assign({}, _vm.$route.params,
                {uuid: purpose.uuid}),
            }}},[_vm._v(" "+_vm._s(_vm.$translation.t("edit"))+" ")]):_c('v-btn',{attrs:{"color":"primary","to":{
              name: _vm.routeNameToNewPurpose,
              params: Object.assign({}, _vm.$route.params,
                // data_usage_purpose_name: purpose.name,
                {data_usage_purpose_slug: purpose.slug}),
            }}},[_vm._v(" "+_vm._s(_vm.$translation.t("fill"))+" ")])],1)])}),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-end align-items-center th-mt-28"},[_c('v-btn',{attrs:{"color":"primary","outlined":!_vm.continueAllowed},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$translation.t("continue"))+" ")])],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }