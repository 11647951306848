<template>
  <div class="w-100">
    <div class="max-w-540 th-mx-auto">
      <h5 class="th-mb-24">
        {{ $translation.t("product.step.data_usage_purpose.title") }}
      </h5>
      <page-loader v-if="loading" />
      <div v-else>
        <th-card
          class="th-px-24 project-document th-mt-12"
          body-classes="th-p-12"
          v-for="(purpose, pKey) in purposes || []"
          :key="pKey"
        >
          <div
            class="project-document__item th-py-16 d-flex align-items-center justify-content-between"
          >
            <div
              class="d-flex flex-column flex-md-row align-items-center flex-grow-1"
            >
              <v-icon
                v-if="purpose.uuid"
                color="success"
                size="24"
                class="th-mr-8"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <h6 class="subtitle2 th-m-0" v-html="purpose.name"></h6>
            </div>
            <v-btn
              color="primary"
              text
              v-if="purpose.uuid"
              :to="{
                name: routeNameToEditPurpose,
                params: {
                  ...$route.params,
                  uuid: purpose.uuid,
                },
              }"
            >
              {{ $translation.t("edit") }}
            </v-btn>
            <v-btn
              color="primary"
              v-else
              :to="{
                name: routeNameToNewPurpose,
                params: {
                  ...$route.params,
                  // data_usage_purpose_name: purpose.name,
                  data_usage_purpose_slug: purpose.slug,
                },
              }"
            >
              {{ $translation.t("fill") }}
            </v-btn>
          </div>
        </th-card>
        <div
          class="d-flex flex-column flex-md-row justify-end align-items-center th-mt-28"
        >
          <v-btn color="primary" :outlined="!continueAllowed" @click="onSubmit">
            {{ $translation.t("continue") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ThCard from "../../../../components/GeneralComponents/ThCard";
import PageLoader from "../../../../components/PageLoader";
import { dataUsagePurposes } from "../../../../utils/service/Questionnaire/index";

export default {
  name: "DataUsagePurposeOverview",
  components: { PageLoader, ThCard },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      existingDataPurposes: [],
      loading: true,
    };
  },
  async beforeCreate() {
    this.existingDataPurposes =
      (await this.$axios.get("/api/v1/business/data/usage-purposes")) || [];
    // this.generalDataPurposes =
    //   (await this.$axios.get("/api/v1/business/data/general-purposes")) || [];
    this.loading = false;
  },
  created() {
    this.$route.params.type = "data_usage_purpose";
    this.SET_QUESTIONS([]);
  },
  beforeDestroy() {
    this.SET_QUESTIONS(null);
  },
  computed: {
    purposes() {
      const mapPurposes = dataUsagePurposes.map((purpose) => {
        const existing = this.existingDataPurposes.find(
          (dataPurpose) =>
            dataPurpose.slug === purpose.slug ||
            dataPurpose.name === purpose.name
        );

        return {
          ...purpose,
          uuid: existing?.uuid || null,
        };
      });

      // Add existing purposes to the list

      return mapPurposes;
    },
    continueAllowed() {
      return !this.purposes.find(({ uuid }) => !uuid);
    },
    routeNameToNewPurpose() {
      if (this.$route.meta.dupnRoute) return this.$route.meta.dupnRoute;

      return this.$route.params.onboarding
        ? "welcome_questionnaire_data_usage_purpose_new"
        : "questionnaire_type_data_usage_purpose_new";
    },
    routeNameToEditPurpose() {
      if (this.$route.meta.dupeRoute) return this.$route.meta.dupeRoute;

      return this.$route.params.onboarding
        ? "welcome_questionnaire_data_usage_purpose_edit"
        : "questionnaire_type_data_usage_purpose_edit";
    },
  },
  methods: {
    ...mapMutations("questionnaire", ["SET_QUESTIONS"]),
  },
};
</script>

<style lang="scss" scoped></style>
